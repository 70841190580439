import axios from '@/common/axios'
import qs from 'qs'

export function addBaseInfo (data) {
  return axios({
    method: 'post',
    url: '/device/base/add',
    data: qs.stringify(data)
  })
}

export function deleteBaseInfo (id) {
  return axios({
    method: 'delete',
    url: '/device/base/delete/' + id
  })
}

export function updateBaseInfo (data) {
  return axios({
    method: 'put',
    url: '/device/base/update',
    data: qs.stringify(data)
  })
}

export function selectBaseInfoInfo (id) {
  return axios({
    method: 'get',
    url: '/device/base/info/' + id
  })
}

export function selectBaseInfoList (query) {
  return axios({
    method: 'get',
    url: '/device/base/list',
    params: query
  })
}
