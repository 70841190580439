<template>
  <div id="deviceCheck">
    <el-dialog
      :title="deviceCheckFormTitle"
      width="880px"
      :visible.sync="deviceCheckDialogVisible"
      :close-on-click-modal="false"
      @close="deviceCheckDialogClose"
    >
      <el-form
        ref="deviceCheckFormRef"
        :model="deviceCheckForm"
        :rules="deviceCheckFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="本厂编号" prop="factoryNo">
              <el-select
                v-model="deviceCheckForm.factoryNo"
                placeholder="请选择设备"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.deviceNo"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设施名称" prop="deviceName">
              <el-input
                v-model="deviceCheckForm.deviceName"
                placeholder="请输入设施名称"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号/规格" prop="spec">
              <el-input
                v-model="deviceCheckForm.spec"
                placeholder="请输入型号/规格"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格" prop="price">
              <el-input
                v-model="deviceCheckForm.price"
                placeholder="请输入价格"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input
                v-model="deviceCheckForm.manufacturer"
                placeholder="请输入生产厂家"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进厂日期" prop="arrivalDate">
              <el-date-picker
                v-model="deviceCheckForm.arrivalDate"
                placeholder="请选择进厂日期"
                value-format="yyyy-MM-dd"
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主要技术参数" prop="technicalParameter">
              <el-input
                v-model="deviceCheckForm.technicalParameter"
                placeholder="请输入主要技术参数"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="随机附件及数量" prop="annexQuantity">
              <el-input
                v-model="deviceCheckForm.annexQuantity"
                placeholder="请输入随机附件及数量"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="随机资料" prop="randomData">
              <el-input
                v-model="deviceCheckForm.randomData"
                placeholder="请输入随机资料"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设施安装调试情况" prop="installationSituation">
              <el-input
                v-model="deviceCheckForm.installationSituation"
                placeholder="请输入设施安装调试情况"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设施验收结论" prop="checkConclusion">
              <el-input
                v-model="deviceCheckForm.checkConclusion"
                placeholder="请输入设施验收结论"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参加验收人员" prop="checkPerson">
              <el-input
                v-model="deviceCheckForm.checkPerson"
                placeholder="请输入参加验收人员"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="deviceCheckForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="deviceCheckFormTitle !== '新增设施验收单'
                  && deviceCheckFormTitle !== '修改设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果" prop="confirmerResult">
              <el-radio-group
                v-model="deviceCheckForm.confirmerResult"
                :disabled="deviceCheckFormTitle !== '确认设施验收单'
                  && deviceCheckFormTitle !== '设施验收单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deviceCheckDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deviceCheckFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设施名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设施名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['DEVICE_CHECK_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deviceCheckPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设施名称" />
      <el-table-column prop="factoryNo" label="本厂编号" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="price" label="价格" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column label="进厂日期">
        <template slot-scope="scope">
          <span v-if="scope.row.arrivalDate">{{ scope.row.arrivalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="technicalParameter" label="主要技术参数" />
      <el-table-column prop="annexQuantity" label="随机附件及数量" />
      <el-table-column prop="randomData" label="随机资料" />
      <el-table-column prop="installationSituation" label="设施安装调试情况" />
      <el-table-column prop="checkConclusion" label="设施验收结论" />
      <el-table-column prop="checkPerson" label="参加验收人员" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="checker" label="验收人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkerDate">{{ scope.row.checkerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmer" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerDate">{{ scope.row.confirmerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确认结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerResult === 1">通过</span>
          <span v-if="scope.row.confirmerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DEVICE_CHECK_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DEVICE_CHECK_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['DEVICE_CHECK_CONFIRM']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确认
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deviceCheckPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDeviceCheck,
  deleteDeviceCheck,
  updateDeviceCheck,
  selectDeviceCheckInfo,
  selectDeviceCheckList,
  confirmDeviceCheck
} from '@/api/universal/deviceCheck'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      deviceCheckDialogVisible: false,
      deviceCheckFormTitle: '',
      deviceCheckForm: {
        id: '',
        deviceName: '',
        factoryNo: '',
        spec: '',
        price: '',
        manufacturer: '',
        arrivalDate: '',
        technicalParameter: '',
        annexQuantity: '',
        randomData: '',
        installationSituation: '',
        checkConclusion: '',
        checkPerson: '',
        remarks: '',
        confirmerResult: '',
        taskId: ''
      },
      deviceCheckFormRules: {
        deviceName: [{ required: true, message: '设施名称不能为空', trigger: ['blur', 'change']}]
      },
      deviceCheckPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      devList: []
    }
  },
  created () {
    selectDeviceCheckList(this.searchForm).then(res => {
      this.deviceCheckPage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    deviceCheckDialogClose () {
      this.$refs.deviceCheckFormRef.resetFields()
    },
    deviceCheckFormSubmit () {
      if (this.deviceCheckFormTitle === '设施验收单详情') {
        this.deviceCheckDialogVisible = false
        return
      }
      this.$refs.deviceCheckFormRef.validate(async valid => {
        if (valid) {
          if (this.deviceCheckFormTitle === '新增设施验收单') {
            await addDeviceCheck(this.deviceCheckForm)
          } else if (this.deviceCheckFormTitle === '修改设施验收单') {
            await updateDeviceCheck(this.deviceCheckForm)
          } else if (this.deviceCheckFormTitle === '确认设施验收单') {
            await confirmDeviceCheck(this.deviceCheckForm)
          }
          this.deviceCheckPage = await selectDeviceCheckList(this.searchForm)
          this.deviceCheckDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deviceCheckFormTitle = '新增设施验收单'
      this.deviceCheckDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeviceCheck(row.id)
        if (this.deviceCheckPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deviceCheckPage = await selectDeviceCheckList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deviceCheckFormTitle = '修改设施验收单'
      this.deviceCheckDialogVisible = true
      this.selectDeviceCheckInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.deviceCheckFormTitle = '确认设施验收单'
      this.deviceCheckDialogVisible = true
      this.selectDeviceCheckInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deviceCheckFormTitle = '设施验收单详情'
      this.deviceCheckDialogVisible = true
      this.selectDeviceCheckInfoById(row.id)
    },
    selectDeviceCheckInfoById (id) {
      selectDeviceCheckInfo(id).then(res => {
        this.deviceCheckForm.id = res.id
        this.deviceCheckForm.deviceName = res.deviceName
        this.deviceCheckForm.factoryNo = res.factoryNo
        this.deviceCheckForm.spec = res.spec
        this.deviceCheckForm.price = res.price
        this.deviceCheckForm.manufacturer = res.manufacturer
        this.deviceCheckForm.arrivalDate = res.arrivalDate
        this.deviceCheckForm.technicalParameter = res.technicalParameter
        this.deviceCheckForm.annexQuantity = res.annexQuantity
        this.deviceCheckForm.randomData = res.randomData
        this.deviceCheckForm.installationSituation = res.installationSituation
        this.deviceCheckForm.checkConclusion = res.checkConclusion
        this.deviceCheckForm.checkPerson = res.checkPerson
        this.deviceCheckForm.remarks = res.remarks
        this.deviceCheckForm.confirmerResult = res.confirmerResult
        this.deviceCheckForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeviceCheckList(this.searchForm).then(res => {
        this.deviceCheckPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeviceCheckList(this.searchForm).then(res => {
        this.deviceCheckPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeviceCheckList(this.searchForm).then(res => {
        this.deviceCheckPage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.deviceNo === value)
        this.deviceCheckForm.deviceName = dev.deviceName
        this.deviceCheckForm.spec = dev.spec
        this.deviceCheckForm.manufacturer = dev.manufacturer
      } else {
        this.deviceCheckForm.deviceName = ''
        this.deviceCheckForm.spec = ''
        this.deviceCheckForm.manufacturer = ''
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['DEVICE_CHECK_CONFIRM']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
